<template>
    <el-dialog title="编辑用户" :visible.sync="DialogShow" append-to-body custom-class='class-dialog' width="700px" @close="onCancel"  :close-on-click-modal='false' :close-on-press-escape='false'>
        <div class="dialog-inside videoDialog">
            <div class="sunbox">
                <div class="sunbox-text">用户账号</div>
                <div class="sunbox-content" style="width:500px">
                    <el-input v-model="admin_acc" placeholder="请输入内容"></el-input>
                </div>
            </div>
            <div class="sunbox">
                <div class="sunbox-text">用户密码</div>
                <div class="sunbox-content" style="width:500px">
                    <el-input v-model="admin_pwd" placeholder="请输入内容"></el-input>
                </div>
            </div>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button type="default" @click="onCancel" class="button-Dialog">取 消</el-button>
            <el-button type="primary" @click="onSubmit" class="button-Dialog">提 交</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            DialogShow:false,
            goods_name:'',//文案名称

            // 提交参数
            id:'',//用户id
            admin_acc:'',//用户账号
            admin_pwd:'',//用户密码
        };
    },
    
    mounted() {
    },

    methods: {
        onDialog(data){
            if(data){
                this.id = data.id
                this.admin_acc = data.admin_acc
                // this.admin_pwd = data.admin_pwd
            }
            this.DialogShow = true
        },
        //提交
        onSubmit(){
            if (this.admin_acc == '') {this.$message({ message: "请填写用户账号", type: "warning" });return;}
            if (this.admin_pwd == '') {this.$message({ message: "请填写用户密码", type: "warning" });return;}
            var param = {
                id:this.id,
                admin_acc:this.admin_acc,
                admin_pwd:this.admin_pwd,
            }
            this.$service.post(this.$api.createAdmin,param, (res)=> {
                if(res.code == '200'){
                    this.$message({message:'操作成功',type: 'success'})
                    this.$emit('onAllDataList');
                    this.onCancel()
                }
            })
        },
        //取消||关闭触发
        onCancel(){
            this.DialogShow = false
            this.id = '',//用户id
            this.admin_acc = '',//用户账号
            this.admin_pwd = ''//用户密码
        },
    },

    

    // methods: {
    // 测试用
    // onceshi(){
    //  var param = {}
    // 	this.$service.get(this.$api.getWxpublicArt,param, (res)=> {
    // 		console.log(123)
    // 		if(res.code == '200'){

    // 		}
    // 	})
    // },
    // }
};
</script>

<style lang="scss" scoped>
.classifyList{
    .group-table{
        .TableList{
            .el-tag{
                cursor: pointer;
                margin-bottom: 10px;
                margin-right: 20px;
            }
        }
    }
}
</style>
<style lang="scss">
</style>