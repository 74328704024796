<template>
    <div class="directorList">
        <div class="padd20 borbut20 paddboutno0">
            <div class="topSearchSou">
                <div class="searchdan time">
                    <div class="title">用户账号：</div>
                    <el-input v-model="admin_acc" placeholder="请输入内容"></el-input>
                </div>
                <div class="searchdan time">
                    <div class="title">创建时间：</div>
                    <el-date-picker v-model="pickerTime" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"></el-date-picker>
                </div>
                <div class="searchdan">
                    <el-button type="primary" icon="el-icon-search" @click="onSearch()">搜索</el-button>
                    <el-button class="Shuarefresh" @click="$router.go(0)" icon='el-icon-refresh-right' >重置数据</el-button>
                </div>
            </div>
        </div>
        <div class="TableList padd20">
            <div class="duoshao">
                <div class="title">用户权限列表</div>
                <el-button type="primary" @click="onDialog()">+ 添加用户</el-button>
                <div class="lvtotal">总条数：{{lvtotal}} 条</div>
            </div>
            <el-table :data="TableList" empty-text="暂无数据">
                <el-table-column prop="id" label="id"></el-table-column>
                <el-table-column prop="admin_acc" label="用户账号"></el-table-column>
                <el-table-column prop="created_at" label="添加时间"></el-table-column>
                <el-table-column prop="created_at" label="状态">
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.is_del" active-value="1" inactive-value="2" @change="ondelAdmin(scope.row)"></el-switch><span style="margin-left:10px">{{scope.row.is_del == 1 ? '正常' : '已停用'}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="date" label="操作" width="300">
                    <template slot-scope="scope">
                        <el-button type="primary" @click="onDialog(scope.row)" style="margin-left:0"> 编 辑 </el-button>
                        <el-button type="primary" @click="onAllotDialog(scope.row)">分配权限</el-button>
                        <!-- <el-button type="primary" @click="ondelAdmin(scope.row)">停用账号</el-button> -->
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination class="lv-page" :page-size="per_page" :pager-count="11" @current-change="DialogCurrentChange" background layout="prev, pager, next" :total="lvtotal" hide-on-single-page :current-page.sync="paramPage"></el-pagination>
            <!-- <el-pagination class="lv-page" :page-size="per_page" :pager-count="11" @size-change="handleSizeChange" :page-sizes="[20, 40, 50, 100]" @current-change="DialogCurrentChange" background layout="sizes,prev, pager, next" :total="lvtotal" hide-on-single-page :current-page.sync="paramPage"></el-pagination> -->
        </div>
        <AdminAccDialog ref="AdminAccDialog" @onAllDataList='onAllDataList'></AdminAccDialog>
        <AllotDialog ref="AllotDialog" @onAllDataList='onAllDataList'></AllotDialog>
    </div>
</template>

<script>
import AdminAccDialog from './adminAccDialog.vue'
import AllotDialog from './AllotDialog.vue'
export default {
    data() {
        return {
            paramData:{},//表格参数
            per_page: null, //一页多少条
            lvtotal: null, //共几条数据
            paramPage:1,//表格当前第几页on


            TableList:[],//数据列表


            pickerTime:'',//时间
            admin_acc:'',//用户名称
        };
    },

    components:{
        AdminAccDialog,
        AllotDialog
    },
    
    mounted() {
        this.onAllDataList()
    },

    methods: {
        //操作
        onDialog(data){
            this.$refs.AdminAccDialog.onDialog(data)
        },
        onAllotDialog(data){
            this.$refs.AllotDialog.onDialog(data)
        },
        //删除管理员
        ondelAdmin(data){
            var param = {
                id:data.id,
                is_del:data.is_del
            }
            console.log(param)
            this.$service.post(this.$api.delAdmin,param, (res)=> {
                if(res.code == '200'){
                    this.$message({type: 'success',message: '操作成功!'});
                    // this.onAllDataList()
                }
            })
        },
        //商品搜索
        onSearch(){
            this.paramData = {
                start_time: this.pickerTime ? this.pickerTime[0] : "", //开始日期
                end_time: this.pickerTime ? this.pickerTime[1] : "", //结束日期
                admin_acc:this.admin_acc
            }
            this.paramPage = 1
            this.onAllDataList()
        },
         // 分页
        DialogCurrentChange(val) {
            console.log(val)
            this.paramPage = val
            this.onAllDataList()
        },
        //数据列表
        onAllDataList(){
            this.TableList = []
            var param = this.paramData
            // param.per_page = this.per_page
            param.page = this.paramPage
            this.$service.get(this.$api.getAdminList,param, (res)=> {
                if(res.code == '200'){
                    this.TableList = res.data.data
                    this.lvtotal = res.data.total
                    this.per_page = res.data.per_page
                }
            })
        },
         //每页多少条触发条件
        handleSizeChange(val){
            this.per_page = val
            this.onAllDataList()
        },
    },
    
};
</script>

<style lang="scss" scoped>
</style>
<style lang="scss">
</style>