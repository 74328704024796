<template>
     <el-dialog title="分配管理员权限" :visible.sync="DialogShow" append-to-body custom-class='class-dialog' width="700px" @close="onCancel"  :close-on-click-modal='false' :close-on-press-escape='false'>
        <div class="dialog-inside videoDialog">
            <!-- <div class="Allotlistdan" v-for="(item,key) in UserPrivilegeList" :key="key">
                <div class="listtitle"><el-checkbox :true-label='2' :false-label= '1' v-model="item.is_check">{{item.name}}</el-checkbox></div>
                <div class="nei-dan" v-for="(item1,key1) in item.children" :key="key1">
                    <div class="listtitle"><el-checkbox :true-label='2' :false-label= '1' v-model="item1.is_check">{{item1.name}}</el-checkbox></div>
                    <div class="nei-dan" v-for="(item2,key2) in item1.children" :key="key2" v-if="item1.children">
                        <div class="listtitle"><el-checkbox :true-label='2' :false-label= '1' v-model="item2.is_check">{{item2.name}}</el-checkbox></div>
                    </div>
                    <div v-else>
                    </div>
                </div>
            </div> -->
            <!-- <div class="sunbox">
                <div class="sunbox-text"></div>
                <div class="sunbox-content" style="width:500px">
                </div>
            </div> -->
            <el-tree :data="UserPrivilegeList" :default-checked-keys="Arrchecked" :default-expanded-keys="Arrchecked" show-checkbox node-key="id" :props="defaultProps" @check="currentChecked" :expand-on-click-node="true" :check-strictly="true" ></el-tree>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button type="default" @click="onCancel" class="button-Dialog">取 消</el-button>
            <el-button type="primary" @click="onSubmit" class="button-Dialog">提 交</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            DialogShow:false,
            goods_name:'',//文案名称


            UserPrivilegeList:[],//当前用户权限列表
            AllPrivilegeList:{},//权限列表
            admin_id:"",//用户id
            defaultProps: {//字段名
                children: 'children',
                label: 'name'
            },
            checkedKeys:'',//
            Arrchecked:[],//默认选中
        };
    },
    
    mounted() {
    },

    methods: {
        //分配选择
        currentChecked(nodeObj, SelectedObj) {//当复选框被点击的时候触发	
            this.checkedKeys = SelectedObj.checkedKeys;
            SelectedObj.halfCheckedKeys.forEach(v => {
                this.checkedKeys.push(v)
            });
        },
        onArrchecked(){
            this.UserPrivilegeList.forEach((v,k) => {
                if(v.is_check == 1){
                    this.Arrchecked.push(v.id)
                }
                if(v.children){
                    v.children.forEach((v1,k1) => {
                        if(v1.is_check == 1){
                            this.Arrchecked.push(v1.id)
                        }
                        if(v1.children){
                            v1.children.forEach((v2,k2) => {
                                if(v2.is_check == 1){
                                    this.Arrchecked.push(v2.id)
                                }
                                if(v2.children){
                                    v2.children.forEach((v3,k3) => {
                                        if(v3.is_check == 1){
                                            this.Arrchecked.push(v3.id)
                                        }
                                        if(v3.children){
                                            v3.children.forEach((v4,k4) => {
                                                if(v4.is_check == 1){
                                                    this.Arrchecked.push(v4.id)
                                                }
                                            });
                                        }
                                    });
                                }
                            });
                        }
                    });
                }
            });
            console.log(this.Arrchecked)
        },
        onDialog(data){
            if(data){
                this.admin_id = data.id
                this.ongetAdminPrivilegeList()
            }
            this.ongetPrivilegeList()
            this.DialogShow = true
        },
        //获取当前用户权限
        ongetAdminPrivilegeList(){
            var param = {
                admin_id:this.admin_id,
            }
            this.$service.get(this.$api.getAdminPrivilegeList,param, (res)=> {
                if(res.code == '200'){
                    this.UserPrivilegeList = res.data
                    this.onArrchecked()
                    console.log(this.UserPrivilegeList)
                }
            })
        },  
        //获取权限列表
        ongetPrivilegeList(){
            var param = {
            }
            this.$service.get(this.$api.getPrivilegeList,param, (res)=> {
                if(res.code == '200'){
                    this.AllPrivilegeList = res.data
                    // console.log(this.AllPrivilegeList)
                }
            })
        }, 
        
        //提交
        onSubmit(){
            var param = {
                admin_id:this.admin_id,
                privilege_id:this.checkedKeys,
            }
            this.$service.post(this.$api.allocationPrivilege,param, (res)=> {
                if(res.code == '200'){
                    this.$message({message:'操作成功',type: 'success'})
                    // this.$emit('ongetVideoGoodsList');
                    this.onCancel()
                }
            })
        },
        //取消||关闭触发
        onCancel(){
            this.DialogShow = false
            this.UserPrivilegeList = [],//当前用户权限列表
            this.AllPrivilegeList = {},//权限列表
            this.admin_id = "",//用户id
            this.checkedKeys = '',//
            this.Arrchecked = []//默认选中
        },
    },

    

    // methods: {
    // 测试用
    // onceshi(){
    //  var param = {}
    // 	this.$service.get(this.$api.getWxpublicArt,param, (res)=> {
    // 		console.log(123)
    // 		if(res.code == '200'){

    // 		}
    // 	})
    // },
    // }
};
</script>

<style lang="scss" scoped>
.classifyList{
    .group-table{
        .TableList{
            .el-tag{
                cursor: pointer;
                margin-bottom: 10px;
                margin-right: 20px;
            }
        }
    }
}
.Allotlistdan{
    width: 640px;
    padding-bottom: 20px;
    padding-top: 20px;
    border-top: 2px solid #ededed;
    line-height: 30px;
    &:first-child{
        border-top: none;
        padding-top: 0;
    }
    .nei-dan{
        padding-left: 20px;
    }
}
</style>
<style lang="scss">
</style>